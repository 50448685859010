import React from "react";
import "../modules/TableFiles.css";

const TableFiles = () => {
  return (
    <div className="m-5 filesdiv">
      <h1>A. GENERAL INFORMATION</h1>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Information</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Name of School</td>
            <td>ACT Amrita INTERNATIONAL SCHOOL</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Address</td>
            <td>
              Dr. H.M Shirur Campus, Sector No.110 (108), Road No. 17, Hubli
              Bypass Road, Navanagar, Bagalkote-587104
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>State</td>
            <td>KARNATAKA</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Phone No</td>
            <td>7026222212/9483370352</td>
          </tr>
        </tbody>
      </table>
      <h1>B. SCHOOL INFORMATION</h1>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>DOCUMENTS AND INFORMATION</th>
            <th>File Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Copies of Affiliation Letter </td>
            <td>
              <a
                href="https://actais.in/files/schoolpermission.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Copies of Trust Deed(Kannada)</td>
            <td>
              <a
                href="https://actais.in/files/trustregistrationcertificate.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              Copy of No Objection Certificate (NOC) Issued, by The State Govt.
              /UT
            </td>
            <td>
              <a href="https://actais.in/files/noc1.pdf" target="_blank">
                View Document
              </a>
            </td>
          </tr>

          <tr>
            <td>4</td>
            <td>Copy of Recognition Certificate </td>
            <td>
              <a href="https://actais.in/files/schoolrecog.pdf" target="_blank">
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              Copy of valid Building safety Certificate issued by Department of
              Public Works
            </td>
            <td>
              {" "}
              <a
                href="https://actais.in/files/buildingsafety.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              Copies of Valid Fire Safety Certificate Issued by the Competent
              Authority
            </td>
            <td>
              <a
                href="https://actais.in/files/firesafetycertificate.pdf"
                target="_blank"
              >
                View Document
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>Copies of Valid Water, Health And Sanitation Certificates</td>
            <td>
              {" "}
              <a href="https://actais.in/files/water.pdf" target="_blank">
                View Document
              </a>{" "}
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>Copies of Land Certificates</td>
            <td>
              <a
                href="https://actais.in/files/landcertificate.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <a
                href="https://actais.in/files/landcertificate01.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>Self Declaration</td>
            <td>
              {" "}
              <a href="DOC" target="_blank">
                View Document
              </a>{" "}
            </td>
          </tr>
        </tbody>
      </table>
      {/* <tr>
            <td></td>
            <td>
              <strong>RESULT AND ACADEMICS</strong>
            </td>
            <td> </td>
          </tr> */}
      <h1>C. RESULT AND ACADEMICS</h1>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>DOCUMENTS AND INFORMATION</th>
            <th>File Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Fee Structure of The School</td>
            <td>
              <a
                href="https://actais.in/files/feestructure.pdf"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>Annual Academics Calendar</td>
            <td>
              <a
                href="https://actais.in/files/academiccalendar202425.xlsx"
                target="_blank"
              >
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>List of School Management Committee (SMC)</td>
            <td>
              <a href="https://actais.in/files/listofSMC.xlsx" target="_blank">
                View Document
              </a>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>List of Parents Teachers Association (PTA) Members</td>
            <td>
              <a href="https://actais.in/files/listofPTA.docx" target="_blank">
                View Document
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <h1>D. SCHOOL INFRASTRUCTURE</h1>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>INFORMATION</th>
            <th>DETAILS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Total Campus Area of The School (In Square Mtr) </td>
            <td>32,37488 square meters</td>
          </tr>
          <tr>
            <td>2</td>
            <td>No. ClassRooms</td>
            <td>12</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Size of The Class Rooms (In Square Mtr)</td>
            <td>54 sq mtrs</td>
          </tr>
          <tr>
            <td>4</td>
            <td>No. and Size of Laboratories (In Sq. Mtr)</td>
            <td>02 54 sq mtrs & 55.74sq mtrs</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Computer Lab - 01</td>
            <td>54 sq mtrs</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Composite Science Lab - 01</td>
            <td>55.74sq mtrs</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Internet Facility </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>8</td>
            <td>No. of Girls Toilets</td>
            <td>06</td>
          </tr>
          <tr>
            <td>9</td>
            <td>No. of Boys Toilets</td>
            <td>8 urinals and 6 toilets</td>
          </tr>
        </tbody>
      </table>
      <h1>E. STAFF'S DETAILS</h1>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>INFORMATION</th>
            <th>DETAILS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Principal </td>
            <td>Shri Raveendra V Hulyal</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Total No. of Teachers</td>
            <td>20</td>
          </tr>
          <tr>
            <td>3</td>
            <td>PGT</td>
            <td>0</td>
          </tr>
          <tr>
            <td>4</td>
            <td>PRT</td>
            <td>06</td>
          </tr>
          <tr>
            <td>5</td>
            <td>TGT</td>
            <td>08</td>
          </tr>
          <tr>
            <td>6</td>
            <td>PTI</td>
            <td>01</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Librarian </td>
            <td>01</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Teachers Section Ratio</td>
            <td>-</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Music Teacher</td>
            <td>01</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Details Of Counselling And Wellness Teacher</td>
            <td>-</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Computer Teacher </td>
            <td>01</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default TableFiles;
